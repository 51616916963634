import axios from "axios";
import {formatDate} from "devextreme/localization";

export default {
    version: '10099',
    language: 'de',

    session_token: null,
    user_id: null,

    googleApi: 'AIzaSyBoGEsyYZ30VYxAm7j0vQFS1kD43s-WhlM',

    menu: [{}],
    routes: [{}],

    host: ((document.location.origin === 'http://localhost:8080') || (document.location.origin === 'http://localhost:8081')) ? 'http://msa3/api/' : (document.location.origin === 'https://dev.myschleppapp.de')?'https://dev.myschleppapp.de/api/':'https://psa3.myschleppapp.de/api/',
    server: ((document.location.origin === 'http://localhost:8080') || (document.location.origin === 'http://localhost:8081')) ? 'http://msa3/' : (document.location.origin === 'https://dev.myschleppapp.de')?'https://dev.myschleppapp.de/':'https://psa3.myschleppapp.de/',

    sendRequest(className, id, attr, value) {
        return new Promise((resolve) => {
            return axios.post(this.host + "validation", {
                className: className,
                id: id,
                attribute: attr,
                value: value
            }, {
                headers: {
                    'Authorization': 'Bearer ' + this.session_token,
                    'last-update-ts': formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss')
                }
            }).then(response => {
                if (response.status === 200) {
                    resolve(true);
                }
            }).catch(() => {
                resolve(false);
            });
        });
    },

    updateHeight(component) {
        if (component)
            component.instance.option("height", (window.innerHeight) - 117);
        else
            return window.innerHeight - 44;
    }
};
